// import logo from './logo.svg';
import './App.css';
import coldBg from './assets/winter.jpg';
import hotBg from './assets/hot.jpg';
import coolBg from './assets/cool.jpg';
import Descriptions from './components/Descriptions.jsx';
import { useEffect, useState } from 'react';
import { getFormattedWeatherData } from './weatherService';

function App() {
  const [city, setCity] = useState("Sydney");
  const [weather, setWeather] = useState(null);
  const [units, setUnits] = useState("metric");
  const [bg, setBg] = useState(hotBg);

  useEffect(() => {
    const fetchWeatherData = async () =>{
      const data = await getFormattedWeatherData(city, units);
      setWeather(data);

      // Dynamic background
      const threshold = units === 'metric' ? 20 : 68;
      if (data.temp <= threshold) setBg(coldBg)
      else setBg(hotBg);
    };
    fetchWeatherData();
  }, [units, city]);

  const handleUnitsClick = (e) => {
    const button = e.currentTarget;
    const currentUnit = button.innerText.slice(1);
    
    const isCelsius = currentUnit === "C";
    button.innerText = isCelsius ? "oF" : "oC";
    setUnits(isCelsius ? "metric" : "imperial");

  }

  const enterKeyPressed = (e) => {
    if (e.keyCode === 13) {
      setCity(e.currentTarget.value);
      e.currentTarget.blur();
    }

  };

  return (
    <div className="app" style={{ backgroundImage: `url(${bg})` }}>
      <div className="overlay">
        {
          weather && (
            <div className="container">
              <div className="section section__inputs">          
                <input onKeyDown={enterKeyPressed} type="text" name="city" placeholder="Enter City..." />
                <button onClick={(e) => handleUnitsClick(e)}>oF</button>
              </div> {/*end section section__inputs */}        
              <div className="section section__temperature">
                <div className="icon">
                  <h3>{`${weather.name}, ${weather.country}`}</h3>
                  <img src={weather.iconURL} alt="weatherIcon"/>
                  <h3>{weather.description}</h3>
                </div> {/*end icon */}
                
                <div className="temperature">
                  <h1>{`${weather.temp.toFixed()} o${units === "metric" ? "C" : "F"}`}</h1>
                </div> {/*end temperature */}
              </div> {/*end section__temperature */}
               
              {/* bottom description */}
              <Descriptions weather={weather} units={units} />
            </div> /*end container */

          )
        }

        
      </div> {/*end overlay */}
    </div> 
  );
}

export default App;
